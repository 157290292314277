export const options = [
  {
    el: "Ei",
    sug: [
      {
        name: "Kala Namak",
        url: "https://amzn.to/33svEhn",
        category: ["Geschmack"],
        description:
          "Das Salz imitiert durch seinen hohen Schwefelgehalt perfekt den Geschmack von Ei.",
      },
      {
        name: "Haferflocken",
        url: "https://amzn.to/3fLCu6v",
        category: ["Bindemittel"],
        description:
          "Etwa eine Viertelstunde sollte die Masse vorab in Wasser ziehen gelassen werden",
        dose: "3–4 Esslöffel zarte Haferflocken = 1 Ei.",
      },
      {
        name: "Leinsamen",
        url: "https://amzn.to/3ljIkgx",
        category: ["Bindemittel"],
        description:
          "Da Leinsamen in Flüssigkeiten aufquellen, sollten sie gemahlen werden, bevor sie in Rezepten zum Einsatz kommen",
      },
      {
        name: "Chiasamen",
        url: "https://amzn.to/39r5nDS",
        category: ["Bindemittel"],
        description:
          "Da Chiasamen in Flüssigkeiten aufquellen, sollten sie gemahlen werden, bevor sie in Rezepten zum Einsatz kommen",
        dose: "1 Esslöffel Chiasamen + 3 Esslöffel Wasser = 1 Ei",
      },
      {
        name: "Tapiokastärke",
        url: "https://amzn.to/3lnTb94",
        category: ["Bindemittel"],
        description: "Vermischen Sie einen Esslöffel Tapiokastärke.",
        dose: "1 Esslöffel Tapiokastärke + 3 Teelöffeln Wasser = 1 Ei",
      },
      {
        name: "Sojamehl",
        url: "https://amzn.to/36hT6iY",
        category: ["Bindemittel"],
        description: "Vermischen Sie einen Esslöffel Sojamehl.",
        dose: "3 Teelöffeln Wasser = 1 Ei",
      },
      {
        name: "VollEy Ei-ersatz",
        url: "https://amzn.to/38Ealej",
        category: ["Ersatzprodukt"],
      },
    ],
  },
  {
    el: "Eischnee",
    sug: [
      {
        name: "Aquafaba",
        category: ["Bindemittel"],
        url: "https://amzn.to/3aLQ7SM",
        description:
          "Der Link führt zu gekochten Kicherebsen, die darin enthaltene Flüssigkeit mit einem Handrührgerät etwa 10 Minuten lang schlagen, bis es zu einer festen, weißen Masse aufsteigt. Die Kicherbsen selbst können vielseitig eingesetzt werden (Hummus, Falafeln, in Salat etc.)",
      },
    ],
  },
  {
    el: "Parmesan",
    sug: [
      {
        name: "Hefeflocken",
        url:
          "https://www.amazon.de/gp/product/B007Q18OVS/ref=as_li_tl?ie=UTF8&tag=dieserak-21&camp=1638&creative=6742&linkCode=as2&creativeASIN=B007Q18OVS&linkId=92ae92f608a78519c733bceb9fb9c52d",
        category: ["Konsistenz"],
      },
    ],
  },
  {
    el: "Gelatine",
    sug: [
      {
        name: "Agar-Agar",
        url:
          "https://www.amazon.de/gp/product/B07YQ8D8ZK/ref=as_li_tl?ie=UTF8&tag=dieserak-21&camp=1638&creative=6742&linkCode=as2&creativeASIN=B07YQ8D8ZK&linkId=8dfcce78357bb7e1c0bc3f18d12bb9af",
        category: ["Konsistenz"],
      },
    ],
  },
  {
    el: "Milch",
    sug: [
      {
        name: "Hafer-Drink",
        url:
          "https://www.amazon.de/gp/product/B08FF5FMSX/ref=as_li_tl?ie=UTF8&tag=dieserak-21&camp=1638&creative=6742&linkCode=as2&creativeASIN=B08FF5FMSX&linkId=06b27bb44ca7081061402ceeb1b4954f",
        category: ["Konsistenz"],
      },
      {
        name: "Hafer-Barista",
        url:
          "https://www.amazon.de/gp/product/B087X8LHHP/ref=as_li_tl?ie=UTF8&tag=dieserak-21&camp=1638&creative=6742&linkCode=as2&creativeASIN=B087X8LHHP&linkId=aa6650ad002adcbc743def613830b29c",
        description:
          "Perfekt für Kaffemilch-Ersatz, lässt sich auch gut aufschäumen",
      },
      {
        name: "Soja-Drink",
        url:
          "https://www.amazon.de/gp/product/B01C8UIRC2/ref=as_li_tl?ie=UTF8&tag=dieserak-21&camp=1638&creative=6742&linkCode=as2&creativeASIN=B01C8UIRC2&linkId=ea1b044dca1333bae7859f96ea27dfb0",
        category: ["Konsistenz"],
      },
      {
        name: "Reis-Drink",
        url:
          "https://www.amazon.de/gp/product/B01FY4XAUI/ref=as_li_tl?ie=UTF8&tag=dieserak-21&camp=1638&creative=6742&linkCode=as2&creativeASIN=B01FY4XAUI&linkId=281d24471f95a48e5e610b096c2d7c6a",
        category: ["Konsistenz"],
      },
      {
        name: "Mandel-Drink",
        url:
          "https://www.amazon.de/gp/product/B013PAGZW8/ref=as_li_tl?ie=UTF8&tag=dieserak-21&camp=1638&creative=6742&linkCode=as2&creativeASIN=B013PAGZW8&linkId=361b6a199785aae23c12d794fba8784c",
        category: ["Konsistenz"],
      },
    ],
  },
  {
    el: "Rührei",
    sug: [
      {
        name: "Tofu",
        url:
          "https://www.amazon.de/gp/product/B06VY9ZHZ7/ref=as_li_tl?ie=UTF8&tag=dieserak-21&camp=1638&creative=6742&linkCode=as2&creativeASIN=B06VY9ZHZ7&linkId=3b0d17e73c8eed7c9f537d693d8d5f26",
        category: ["Konsistenz"],
        description:
          "5 Esslöffel pürierter Seidentofu kann als Ersatz für ein Rührei verwendet werden",
      },
      {
        name: "Kala Namak",
        url: "https://amzn.to/33svEhn",
        category: ["Geschmack"],
        description:
          "Das Salz imitiert durch seinen hohen Schwefelgehalt perfekt den Geschmack von Ei.",
      },
      {
        name: "Kurkuma",
        url:
          "https://www.amazon.de/gp/product/B0839FXZG5/ref=as_li_tl?ie=UTF8&tag=dieserak-21&camp=1638&creative=6742&linkCode=as2&creativeASIN=B0839FXZG5&linkId=673864ee5314815050c806f86d227ad0",
        category: ["Farbe"],
        description: "Die gelbliche Farbe erinnert an Ei",
      },
    ],
  },
  {
    el: "Hackfleisch",
    sug: [
      {
        name: "Soja-Granluat / Soja-Flocken",
        category: ["Konsistenz"],
        url:
          "https://www.amazon.de/gp/product/B082VNTQ91/ref=as_li_tl?ie=UTF8&tag=dieserak-21&camp=1638&creative=6742&linkCode=as2&creativeASIN=B082VNTQ91&linkId=0f2418e0532620511ff9829fe613c137",
        description:
          "Sojagranulat in eine Schüssel geben, kochendes Wasser darauf geben und etwas Gemüsebrühe darin auflösen. Das Granulat muss frei schwimmen, weil es sich noch mit dem Wasser vollsaugt. Ca. 15 Minuten quellen lassen.",
      },
      {
        name: "Gewürz",
        category: ["Gewürz"],
        url: "https://amzn.to/3rxVbjy",
      },
      {
        name: "SonnenblumenHACK",
        url:
          "https://www.amazon.de/gp/product/B074ZNLV3S/ref=as_li_tl?ie=UTF8&tag=dieserak-21&camp=1638&creative=6742&linkCode=as2&creativeASIN=B074ZNLV3S&linkId=440c7d1278601b01230eaca43049ecf0",
      },
    ],
  },
  // {
  //   el: "Fleisch",
  //   sug: [
  //     {
  //       name: "Tofu",
  //       url: "https://www.amazon.de/gp/product/B06VY9ZHZ7/ref=as_li_tl?ie=UTF8&tag=dieserak-21&camp=1638&creative=6742&linkCode=as2&creativeASIN=B06VY9ZHZ7&linkId=3b0d17e73c8eed7c9f537d693d8d5f26",
  //       category: ["Konsistenz"],
  //     },
  //     {
  //       name: "Tempeh",
  //       category: ["Konsistenz"],
  //     },
  //     {
  //       name: "Sojaschnetzel",
  //       category: ["Konsistenz"],
  //     },
  //   ],
  // },
  {
    el: "Pulled-Pork",
    sug: [
      {
        name: "Jackfruit",
        url:
          "https://www.amazon.de/gp/product/B0713X5BQZ/ref=as_li_tl?ie=UTF8&tag=dieserak-21&camp=1638&creative=6742&linkCode=as2&creativeASIN=B0713X5BQZ&linkId=391186980b755ab8b451986d9de83e41",
        category: ["Konsistenz"],
      },
    ],
  },
  {
    el: "Burger Patty",
    infoDescription:
      "Mittlerweile gibt es in fast allen Supermärkten, neben bekannten wie Beyond Meat, ein veganes Eigenmarken Patty",
    sug: [
      {
        name: "Gemüse Burger",
        url: "https://amzn.to/3rzz78i",
        category: ["Konsistenz"],
      },
      {
        name: "Veggie Burger",
        url: "https://amzn.to/3rtLF0J",
        category: ["Konsistenz"],
      },
    ],
  },
  {
    el: "Honig",
    sug: [
      {
        name: "Agavendicksaft",
        url:
          "https://www.amazon.de/gp/product/B084YYB79Z/ref=as_li_tl?ie=UTF8&tag=dieserak-21&camp=1638&creative=6742&linkCode=as2&creativeASIN=B084YYB79Z&linkId=20c7770c3e984c2f64107f538ce9528c",
        category: ["Konsistenz"],
      },
      {
        name: "Zuckerrübensirup",
        url:
          "https://www.amazon.de/gp/product/B00H27EY5G/ref=as_li_tl?ie=UTF8&tag=dieserak-21&camp=1638&creative=6742&linkCode=as2&creativeASIN=B00H27EY5G&linkId=150bd54b0a0cbfaccf70b79a1998c9e2",
        category: ["Konsistenz"],
      },
      {
        name: "Löwenzahn-Honig",
        url:
          "https://www.amazon.de/gp/product/B0792K888R/ref=as_li_tl?ie=UTF8&tag=dieserak-21&camp=1638&creative=6742&linkCode=as2&creativeASIN=B0792K888R&linkId=1306d27073b5ff6042dd4cb3e7e3e7d7",
        category: ["Konsistenz"],
      },
      {
        name: "Dattelsirup",
        url:
          "https://www.amazon.de/gp/product/B00X7XJ0T4/ref=as_li_tl?ie=UTF8&tag=dieserak-21&camp=1638&creative=6742&linkCode=as2&creativeASIN=B00X7XJ0T4&linkId=84581e1386f93ecd785adf9147c72013",
        category: ["Konsistenz"],
      },
    ],
  },
  {
    el: "Mayonnaise",
    sug: [
      {
        name: "Hellmann's Vegan Mayonnaise",
        url:
          "https://www.amazon.de/gp/product/B084N21W9D/ref=as_li_tl?ie=UTF8&tag=dieserak-21&camp=1638&creative=6742&linkCode=as2&creativeASIN=B084N21W9D&linkId=f2b4c807a9b9f76ef52290f63b700f5c",
        category: ["Konsistenz"],
      },
    ],
  },
  {
    el: "Sahne",
    sug: [
      {
        name: "Natumi Dinkel-Kochcreme",
        url:
          "https://www.amazon.de/gp/product/B005P2MCVI/ref=as_li_tl?ie=UTF8&tag=dieserak-21&camp=1638&creative=6742&linkCode=as2&creativeASIN=B005P2MCVI&linkId=abc24950e72b98ee750717ac60a42568",
        category: ["Konsistenz"],
      },
      {
        name: "Schlagfix gesüßte Schlagcreme",
        url:
          "https://www.amazon.de/gp/product/B014T48YKA/ref=as_li_tl?ie=UTF8&tag=dieserak-21&camp=1638&creative=6742&linkCode=as2&creativeASIN=B014T48YKA&linkId=b61c4ae0a1d8510934463a7ffe216d5b",
        category: ["Konsistenz"],
      },
    ],
  },
  {
    el: "Joghurt",
    sug: [
      {
        name: "Sojajoghurt",
        category: ["Ersatzprodukt"],
        url:
          "https://www.amazon.de/gp/product/B01ARKEKBC/ref=as_li_tl?ie=UTF8&tag=dieserak-21&camp=1638&creative=6742&linkCode=as2&creativeASIN=B01ARKEKBC&linkId=b33e1bfc668ae0151e31991e1e967c14",
      },
    ],
  },
  // {
  //   el: "Lachs",
  //   sug: [
  //     {
  //       name: "Karotten",
  //       category: ["Konsistenz"],
  //     },
  //   ],
  // },
  {
    el: "Leberwurst",
    sug: [
      {
        name: "Leberwurst",
        category: ["Ersatzprodukt"],
        url:
          "https://www.amazon.de/gp/product/B0771FFKKK/ref=as_li_tl?ie=UTF8&tag=dieserak-21&camp=1638&creative=6742&linkCode=as2&creativeASIN=B0771FFKKK&linkId=d162c648b700187660650e3c488e08c3",
      },
    ],
  },
  {
    el: "Milch Schokolade",
    sug: [
      {
        name: "Frankonia Schokolade",
        category: ["Ersatzprodukt"],
        url:
          "https://www.amazon.de/gp/product/B01B1NKRLG/ref=as_li_tl?ie=UTF8&tag=dieserak-21&camp=1638&creative=6742&linkCode=as2&creativeASIN=B01B1NKRLG&linkId=df60434bdd977c0c64fc5d99ecc07991",
      },
    ],
  },
  // {
  //   el: "Nougat Schokolade",
  //   sug: [
  //     {
  //       name: "Rapunzel Nirwana Schokolade",
  //       category: ["Ersatzprodukt"],
  //       url:
  //         "https://www.amazon.de/gp/product/B00OKD7H86/ref=as_li_tl?ie=UTF8&tag=dieserak-21&camp=1638&creative=6742&linkCode=as2&creativeASIN=B00OKD7H86&linkId=a132b9381b7062df4401784d2cde6d41",
  //     },
  //   ],
  // },
];
