<template>
  <div
    class="suggestion__line"
    :class="{ 'suggestion__line--clickable': replacement.description }"
    @click="showMore = !showMore"
  >
    <a
      v-if="replacement.url"
      target="_blank"
      :href="replacement.url"
      :alt="replacement.name"
      :title="replacement.name"
    >
      <span class="v-centered"> {{ replacement.name }}* </span>
    </a>
    <span v-else class="v-centered">
      {{ replacement.name }}
    </span>

    <div class="v-centered">
      <button v-if="replacement.description">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="black"
          width="18"
          height="18"
          :class="{ turn180: showMore }"
        >
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
        </svg>
      </button>
    </div>
  </div>
  <div
    class="suggestion__description"
    v-if="replacement.description && showMore"
  >
    <div class="tags">
      <span class="tag" v-for="(tag, i) in replacement.category" :key="tag + i">{{
        tag
      }}</span>
    </div>
    {{ replacement.description }}
    <template v-if="replacement.dose">
      <br />
      <b>{{ replacement.dose }}</b>
    </template>
  </div>
</template>

<script>
import { ref, watchEffect } from "vue";
export default {
  props: {
    replacement: Object,
    openDetails: Boolean,
  },
  setup(props) {
    const showMore = ref(null);
    watchEffect(()=>{
      if (props.openDetails) {
        showMore.value = true;
      }
      else {
        showMore.value = false;
      }
    })

    return { showMore };
  },
};
</script>

<style scoped lang="scss">
button {
  border: none;
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: var(--space-xxs) 0;
  border-radius: var(--space-xs);
}

a {
  display: inherit;
  text-decoration: none;
  font-weight: 500;
}


.tag {
  border-radius: var(--space-xs);
  padding: 0px 6px;
  margin-right: var(--space-xs);
  background-color: var(--col-green-bg);
  font-weight: 400;
  font-size: var(----fontsize-m);
  display: inline-flex;
  align-items: center;
}

.tags {
  margin-bottom: var(--space-xs);
  display: inline;
}

.v-centered {
  display: flex;
  align-items: center;
}

.h-centered {
  display: flex;
  justify-content: space-between;
}

.suggestion {
  &__line {
    display: flex;
    justify-content: space-between;
    &--clickable {
      cursor: pointer;
    }
  }
  &__description {
    margin-top: var(--space-s);
    margin-bottom: var(--space-s);
    font-size: var(--fontsize-m);

    @media screen and (max-width: 720px) {
      margin-top: var(--space-xs);
    }
  }
  &__link {
    display: flex;
  }
}

.turn180 {
  transform: rotate(180deg);
}

svg {
  min-width: 1var (--space-s);
}

.hide {
  opacity: 0;
  pointer-events: none;
}
</style>