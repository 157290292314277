<template>
  <div class="main">
    <div class="bg-header">
      <Logo :doAnimation="animation" class="bg-header__item" />
    </div>
    <div class="bg-header select" :class="{ 'select--opened': opened }">
      <div class="bg-header__item select__input-wrap">
        <input
          ref="inputRef"
          class="select__input"
          @input="open($event)"
          :value="selection"
          type="text"
        />
        <svg
          class="select__icon"
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 0 24 24"
          width="24"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path
            d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
          />
        </svg>
      </div>
    </div>
    <div class="content">
      <ul class="select__dropdown" v-show="showSuggestion">
        <li
          class="select__element"
          v-for="(suggestion, i) in matches"
          :key="suggestion + i"
        >
          <h2 class="mr-2">
            {{ suggestion.el }}
          </h2>
          <div class="info" v-if="suggestion.infoDescription">
            <svg
            class="info__icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="black"
              width="18px"
              height="18px"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path
                d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"
              />
            </svg>
            <span>{{ suggestion.infoDescription }}</span>
          </div>

          <div
            class="suggestion"
            v-for="(replacement, i) in suggestion.sug"
            :key="replacement + i"
          >
            <ListItem :replacement="replacement" :openDetails="more" />
          </div>
        </li>
      </ul>
      <div class="ml-4" v-show="!matches.length">
        Leider nichts gefunden
        <a href="#" @click="resetSelection">Zurücksetzen</a>
      </div>
    </div>
  </div>
  <Footer />
</template>

<script>
import { computed, onMounted, ref, watchEffect, watch } from "vue";

import { options } from "./options.js";

import Footer from "./components/Footer.vue";
import Logo from "./components/Logo.vue";
import ListItem from "./components/ListItem.vue";

export default {
  components: {
    Footer,
    Logo,
    ListItem,
  },
  setup() {
    const selection = ref("");
    const opened = ref(false);
    const more = ref(false);
    const inputRef = ref(null);

    const open = (event) => {
      selection.value = event.target.value;
      opened.value = true;
    };

    const matches = computed(() => {
      return options.filter((option) =>
        option.el.toLowerCase().includes(selection.value.toLowerCase())
      );
    });

    const rotateSTime = 820;
    document.documentElement.style.setProperty(
      "--rotate-s-time",
      rotateSTime + "ms"
    );

    // const rotateSTimeString = rotateSTime + "ms";

    const isMobile = computed(() => {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      }
      return false;
    });

    onMounted(() => {
      if (!isMobile.value) inputRef.value.focus();
    });

    watchEffect(() => {
      let count = 0;
      matches.value.forEach((match) => {
        match.sug.forEach((suggestion)=>{
            if(suggestion.description) count++;
        })
      });
      if (count < 5) {
        more.value = true;
      } else {
        more.value = false;
      }
    });

    const animation = ref(false);
    const doAnimation = () => {
      animation.value = true;
      setTimeout(() => {
        animation.value = false;
      }, rotateSTime);
    };

    watch(matches, (prev, next) => {
      if (JSON.stringify(prev) !== JSON.stringify(next)) {
        window.scrollTo(0, 0);
        if (!animation.value) {
          setTimeout(() => {
            doAnimation();
          }, 50);
        }
      }
    });

    const resetSelection = () => {
      selection.value = "";
      inputRef.value.focus();
      inputRef.value.click();
    };

    const showSuggestion = () => {
      return (
        selection.value !== "" &&
        matches.value.length != 0 &&
        opened.value === true
      );
    };

    return {
      options,
      open,
      opened,
      selection,
      showSuggestion,
      matches,
      inputRef,
      more,
      resetSelection,
      animation,
    };
  },
};
</script>

<style lang="scss">
// @import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500&display=swap");

:root {
  --space-xxs: 2px;
  --space-xs: 4px;
  --space-s: 8px;
  --space-m: 16px;
  --space-l: 32px;
  --space-xl: 48px;
  --fontsize-xs: 10px;
  --fontsize-s: 12px;
  --fontsize-m: 14px;
  --fontsize-l: 16px;
  --fontsize-xl: 24px;
  --fontsize-xxl: 32px;
  --content-max-width: 540px;
  --col-green: rgb(27, 125, 57);
  --col-green-bg: rgb(187, 224, 199);
}

body {
  font-family: Quicksand;
  background: #ffffff;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.4;
  font-size: var(--fontsize-l);
}

b {
  font-weight: 500;
}

.main {
  min-height: 100vh;
}

#app {
  width: 100%;
}

p {
  margin-top: 0;
}

.info {
  display: flex;
  border-radius: 2px;
  background-color: var(--col-green-bg);
  padding: var(--space-s) var(--space-m);
  margin-bottom: var(--space-s);
  &__icon {
    flex-shrink: 0;
    margin-right: var(--space-s);
  }
}

.bg-header {
  background-color: #f8f8f8;
  text-align: center;
  &__item {
    max-width: var(--content-max-width);
  }
}

.select {
  padding: var(--space-s);
  border-bottom: 1px solid #e8e8e8;
  position: sticky;
  top: 0;
}

.hide {
  opacity: 0;
  pointer-events: none;
}

.mr-2 {
  margin-right: var(--space-xs);
}
.ml-2 {
  margin-left: var(--space-xs);
}
.ml-4 {
  margin-left: 20px;
}

.d-block {
  display: block;
}

h1 {
  font-weight: 200;
  margin: 0;
  padding: 12px 0;
  text-align: center;
}

h2 {
  margin-bottom: var(--space-xs);
}

.select {
  &__input {
    background: #fff;
    display: flex;
    border: 1px solid #dfe1e5;
    box-shadow: none;
    border-radius: var(--space-l);
    height: 44px;
    margin: 0 auto;
    padding: 0 var(--space-l) 0 var(--space-m);
    width: 100%;
    box-sizing: border-box;

    &-wrap {
      position: relative;
      max-width: var(--content-max-width);
      text-align: center;
      margin: auto;
    }

    &:focus,
    &:hover {
      outline: none;
      box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
      border-color: rgba(223, 225, 229, 0);
    }
  }

  &__icon {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    fill: #9aa0a6;
  }

  &__dropdown {
    padding: 0;
    list-style: none;
  }
}

.content {
  max-width: var(--content-max-width);
  margin: auto;
  padding: 0 var(--space-m) 0 var(--space-m);
}

.suggestion {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--space-s);
  margin-top: var(--space-s);

  &__link {
    // text-decoration: none;
    font-size: var(--fontsize-m);
  }

  &__divider {
    color: #212121;
  }
}

.checkbox {
  margin-top: 2px;
  display: flex;
  align-items: center;
  &__text {
    font-size: var(--fontsize-m);
  }
}
</style>
